import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { PageContextPropsType } from '../../../i18n/i18n'
import LayoutSimpleFooter from '../../components/LayoutSimpleFooter'
import SEO from '../../components/SEO'
import FormEbook from '../../components/FormEbook'
import classNames from 'classnames/bind'
import * as EbookPageStyles from '../../styles/EbookPage.css'

const cx = classNames.bind(EbookPageStyles)

export default function EbookPage({ pageContext }: PageContextPropsType) {
  const reCaptchaKey = process.env.GATSBY_RECAPTCHA_CLIENT_SECRET_KEY || ''
  return (
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
      <LayoutSimpleFooter pageContext={pageContext}>
        <SEO
          title="Googleオプティマイズ比較資料 | KARTE Blocks"
          description="KARTE Blocksのプロダクト概要やユースケース、Googleオプティマイズとの比較を含んだ資料をダウンロードいただけます。"
          path="/ebook/go/"
        />

        <main className={cx('Outer')}>
          <div className={cx('Container')}>
            <section className={cx('Content')}>
              <h1 className={cx('Headline')}>
                Googleオプティマイズ
                <br className="sp" />
                比較資料
              </h1>

              <StaticImage src="../../images/ebook/go-thumbnail.png" alt="資料の表紙" />

              <section className={cx('Details')}>
                <h2 className={cx('Details_Headline')}>資料の内容</h2>
                <ol className={cx('Details_Outline')}>
                  <li className={cx('Details_Item')}>サイト改善の重要性・よくあるお悩み</li>
                  <li className={cx('Details_Item')}>KARTE Blocks5つの特徴</li>
                  <li className={cx('Details_Item')}>Googleオプティマイズとの違い</li>
                  <li className={cx('Details_Item')}>活用事例・成果</li>
                  <li className={cx('Details_Item')}>プランについて</li>
                </ol>
              </section>
            </section>

            <FormEbook actionUrl="https://deal.karte.io/l/819613/2023-02-08/92bm5" actionName="blockskarteio/ebook/" />
          </div>
        </main>
      </LayoutSimpleFooter>
    </GoogleReCaptchaProvider>
  )
}
